<script setup lang="ts">
const { $getTranslate } = useNuxtApp();
const Logo = defineAsyncComponent(
  () => import("~/assets/svg/logo_facebook.svg"),
);

const props = defineProps({
  appId: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["success", "error"]);

const loadFacebookSDK = async (d, s, id) => {
  let js;
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
};

const initFacebook = async () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: props.appId,
      cookie: true,
      xfbml: true,
      version: "v13.0",
    });
    window.FB.AppEvents.logPageView();
  };
};

const logInWithFacebook = async () => {
  await loadFacebookSDK(document, "script", "facebook-jssdk");
  await initFacebook();
  window.FB.login((response) => {
    if (response.authResponse) emit("success", response);
    else emit("error");
  });
  return false;
};

onMounted(async () => {
  await loadFacebookSDK(document, "script", "facebook-jssdk");
  await initFacebook();
});
</script>

<template>
  <button type="button" @click.prevent="logInWithFacebook">
    <component :is="Logo" class="w-6 h-6" />
    <span class="font-bold text-white text-sm 3xl:text-xl leading-6">{{
      $getTranslate("login_form_button_facebook")
    }}</span>
  </button>
</template>

<style scoped lang="postcss">
button {
  @apply flex py-2.5 px-4 lg:py-3 3xl:py-4 items-center transition-colors duration-200 justify-center gap-4 w-full border border-[#1877F2] rounded bg-[#1877F2] hover:bg-blue-500 active:bg-blue-500 max-h-9 lg:max-h-10 3xl:max-h-11;
}
button span {
  font-family: Helvetica;
}
</style>
