<template>
  <div class="w-full relative justify-center flex items-end gap-3">
    <div class="divider" aria-hidden="true" />
    <slot></slot>
    <div class="divider" aria-hidden="true" />
  </div>
</template>

<style scoped lang="postcss">
.divider {
  @apply flex-1 bg-white h-[1px] mb-[5px];
}
</style>
