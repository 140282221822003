<script setup lang="ts">
const props = defineProps({
  callback: {
    type: Function,
    required: true,
  },
  error: {
    type: Function,
    required: true,
  },
});

const { $getTranslate } = useNuxtApp();
const config = useRuntimeConfig();
const Logo = defineAsyncComponent(() => import("~/assets/svg/logo_google.svg"));

const googleLoginCallback = (response) => {
  if (response) {
    props.callback(response);
  } else {
    props.error();
  }
};

const createFakeGoogleWrapper = () => {
  const googleLoginWrapper = document.createElement("div");
  googleLoginWrapper.style.display = "none";
  googleLoginWrapper.classList.add("custom-google-button");

  document.body.appendChild(googleLoginWrapper);

  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: "icon",
    width: "200",
  });

  const googleLoginWrapperButton =
    googleLoginWrapper.querySelector("div[role=button]");

  return {
    click: () => {
      googleLoginWrapperButton.click();
    },
  };
};

onMounted(() => {
  if (config.public.GOOGLE_CLIENT_ID) {
    window.google.accounts.id.initialize({
      client_id: config.public.GOOGLE_CLIENT_ID,
      ux_mode: "popup",
      callback: googleLoginCallback,
    });
    const googleButtonWrapper = createFakeGoogleWrapper();

    window.handleGoogleLogin = () => {
      googleButtonWrapper.click();
    };
  }
});
</script>

<template>
  <button type="button" onclick="handleGoogleLogin()">
    <component :is="Logo" class="w-6 h-6" />
    <span class="font-medium font-secondary 3xl:text-xl leading-tightest">{{
      $getTranslate("login_form_button_google")
    }}</span>
  </button>
</template>

<style scoped lang="postcss">
button {
  @apply flex py-2.5 px-4 lg:py-3 3xl:py-4 items-center transition-colors duration-200 justify-center gap-4 w-full rounded bg-white hover:bg-[#eeeeee] active:bg-[#eeeeee] max-h-9 lg:max-h-10 3xl:max-h-11;
  box-shadow:
    0px 2px 3px 0px rgba(0, 0, 0, 0.17),
    0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}
button span {
  color: rgba(0, 0, 0, 0.54);
}
</style>
