<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: "text",
  },
  size: {
    type: String,
    default: "base",
  },
  placeholder: {
    type: String,
    default: "Placeholder",
  },
  modelValue: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const SIZES = {
  small: "px-4 py-1 md:py-2 3xl:py-2.5",
  base: "py-2.5 md:py-3 3xl:py-4 px-4",
};

const emit = defineEmits(["update:modelValue"]);

const isFocused = ref(false);

const isError = computed(() => props.errors?.length > 0);

const size = computed(() => {
  if (props.size in SIZES) {
    return SIZES[props.size];
  }
  return SIZES.base;
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});
</script>

<template>
  <div class="w-full input-wrapper" :class="{ error: isError }">
    <label :for="name">{{ label }}</label>
    <div
      class="input-field"
      :class="[size, { focused: isFocused }, { 'opacity-40': isDisabled }]"
    >
      <slot name="iconLeft"></slot>
      <input
        :id="name"
        v-model="value"
        :name="name"
        :type="type"
        class="flex-1"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <slot name="iconRight"></slot>
    </div>
    <p v-if="isError" class="error-message">{{ errors[0] }}</p>
  </div>
</template>

<style scoped lang="postcss">
.input-wrapper label {
  @apply inline-block text-sm leading-4 lg:text-base lg:leading-5 3xl:text-lg 3xl:leading-6 mb-2;
}
.input-field {
  @apply flex items-center w-full gap-4 border transition-colors duration-200 border-interface-form-outline md:max-h-10 lg:max-h-full;
  background: rgba(0, 0, 0, 0.4);
}
.input-field:hover {
  box-shadow: 0px 0px 0px 4px rgba(152, 162, 179, 0.16);
}
.input-field.focused {
  @apply border-primary-base;
  box-shadow: 0px 0px 0px 4px rgba(0, 82, 204, 0.16);
}
input {
  @apply bg-transparent border-0 text-white font-secondary text-base leading-6 focus:outline-none placeholder-interface-form-secondary;
}
.error label {
  @apply text-system-error-text;
}
.error .input-field,
.error .input-field.focused {
  @apply border-system-error-text;
}
.error-message {
  @apply font-secondary text-system-error-text mt-2 text-2xs lg:text-xs 3xl:text-sm;
}
</style>
